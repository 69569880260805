<template>
  <section>
    <div class="headerjob">
      <v-container class="container-custom">
        <v-row>
          <v-col> <h1 class="titlepage">Why Neco Jobs</h1></v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="container-custom">
      <div class="boxinside">
        <p>
          In this age of the internet where social media has taken the world by
          storm, the level of communication is becoming easier and
          sophisticated. Using such a modern maneuver, Neco Jobs bridges the gap
          between employers and job seekers. It is creating a simple yet
          effective platform to advertise and look for jobs from a variety of
          institutional backgrounds. Providing you with a whole world of jobs; a
          whole world of candidates, Neco Jobs provides you with extensive
          options to choose from.<br><br> With the proliferation of today’s technology,
          Neco reaches as many job seekers as possible. It introduces them as an
          easy method to search for an array of jobs, apply where they are
          interested in, and respond to any job queries from anywhere and at any
          time. Likewise, Neco features the option of job alerts after you get
          connected with us. After knowing the job you prefer, these alerts
          deliver all the relevant jobs that match the criteria you set. Alike,
          Neco is a leading professional HR consulting organization that
          specializes in the field of Permanent staffing, HR Consulting &
          Manpower Outsourcing. <br><br>It believes in doing business simply, with
          clarity and honesty. It is committed to focus on continuous
          improvement and deliver betterment for the best results in every
          aspect. With a team of professional expertise, versatile team, precise
          networking and communication, it aims to craft the future and ensure
          career growth of every deserving individual & organization. So, get
          connected with us today for meeting your dream job and any kind of
          career guidance if necessary! Sign up now, you are only a single away.
        </p>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Privacy Policy",
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.boxinside {
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  background: $white;
  margin-bottom: 20px;
  padding: 30px 40px;
  background: $white;
  margin-top: 20px;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }

  h1 {
    font-size: $titlefont;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  h3 {
    font-size: $titlefonth4;
    font-weight: 500;
    margin: 10px 0;
  }
  p {
    font-size: $normal_lfont;
    color: $paragraphcolor;
  }
  span {
    margin: 7px 0;
    display: block;
    font-weight: 500;
  }
}
</style>
